import { useCallback } from "react"

import { useWorkspaceSubscription } from "@supernovaio/cloud/hooks/data/useWorkspaceSubscription"
import { useGetCurrentRole } from "@supernovaio/cloud/hooks/useGetCurrentRole"
import {
  AclPermissionResolution,
  FeaturePermissionResolution,
  UserRole,
} from "@supernovaio/sdk"

import {
  AclOperation,
  AclOperations,
  aclSafeCheckAccess,
  isDesignSystemOperation,
} from "@supernova-studio/acl"

import { useFeatureFlags } from "../utils/featureFlags/useFeatureFlags"

import { useAcls } from "./useAcls"

export type AclResources =
  | "workspaces"
  | "designSystems"
  | "designSystemVersions"
  | "designSystemSources"
  | "tokens"
  | "designSystemBrands"

export type AclActions = "create" | "update" | "delete" | "read"

// TODO (ACL): This isn't safe as it's been copied from the backend and can change
export type WorkspaceAclActions =
  | "read"
  | "members.read"
  | "invitations.read"
  | "onboarding.update"
  | "handle.check"
  | "profile.update"
  | "upgrade"
  | "invitations.resend"
  | "invitations.delete"
  | "invitations.validate"
  | "members.roles.update"
  | "members.delete"
  | "subscription.inspect"
  | "subscription.update"
  | "subscription.cancel"
  | "ip_whitelist.manage"
  | "sso.inspect"
  | "sso.manage"
  | "npm-registry.manage"
  | "ownership.transfer"
  | "delete"
  | "integration.any.read"
  | "integration.gitProviders.create"
  | "integration.figmaProviders.create"
  | "integration.gitProviders.delete"
  | "integration.figmaProviders.delete"

export type Feature =
  | "brands"
  | "codegenSchedules"
  | "customDocumentationExporter"
  | "customDocumentationUrl"
  | "customDocumentationViewButton"
  | "designSystemSlug"
  | "designSystemSourceAutoImport"
  | "designSystemSources"
  | "designSystemVersions"
  | "designSystems"
  | "ipWhitelisting"
  | "npmRegistry"
  | "publicDocumentation"
  | "sso"
  | "themes"
  | "workspacePaidSeats"
  | "workspaceViewers"
  | "protectedPages"
  | "approvals"
  | "selectivePublishing"
  | "designSystemAccessModes"
  | "designSystemRoles"

export type ResourceAction =
  | { resource: "workspaces"; action: WorkspaceAclActions }
  | {
      resource: Exclude<AclResources, "workspaces">
      action: AclActions
    }

type AclPermissionResolutionResult = AclPermissionResolution & {
  isLoading: boolean
}

type FeaturePermissionResolutionResult = FeaturePermissionResolution & {
  isLoading: boolean
}

// TODO (ACL): comment - stop mentioning legacy functions
/**
 * The main hook to deal with plan restrictions and ACL permissions.
 *
 * ### Plan restrictions
 * `getFeaturePermission` is used to check if a specific feature is allowed by the workspace's subscription.
 * There are two types of restrictions:
 * - Simple boolean restrictions (e.g. `publicDocumentation`)
 * - Restrictions with a maximum number of entities (e.g. `designSystemVersions`).
 * This type requires you to pass the current number of entities. In the response, you will get the maximum number of entities allowed.
 *
 * ```typescript
 * const hasPrivatePagePermission = getFeaturePermission("protectedPages").hasPermission
 * ```
 *
 * ### ACL permissions
 *
 * `getPermission` is used to check if a specific action is allowed to the current user.
 * This is the old way of checking permissions and should be avoided.
 * Instead, we should prefer `getPermissionNew` and `hasPermissionNew`.
 * These functions are connected to "@supernova-studio/acl" package.
 * These functions take care of differentiating between workspace and design system operations,
 * which is important for DS roles (they can be different from WS roles).
 *
 * ```typescript
 * const canEditExporter = hasPermissionNew(AclOperations.ExporterUpdate)
 * ```
 *
 * @param wsId ID of the current workspace
 * @param dsId ID of the current design system
 */
export function usePermissions(
  wsId: string | undefined,
  dsId: string | undefined
) {
  const { data: acls, isLoading: isAclsLoading } = useAcls(wsId)

  const { data: subscription, isLoading: isSubscriptionLoading } =
    useWorkspaceSubscription({ workspaceId: wsId })

  const { printAclDebugInfo } = useFeatureFlags()
  const getCurrentRole = useGetCurrentRole(wsId, dsId)

  const currentProductFeatures = subscription?.featuresSummary

  const getFeaturePermission = useCallback(
    (
      feature: Feature,
      numOfEntities?: number
    ): FeaturePermissionResolutionResult => {
      if (isSubscriptionLoading) {
        return { hasPermission: false, reason: "loading", isLoading: true }
      }

      if (!wsId) {
        return {
          hasPermission: false,
          reason: "missing-workspace",
          isLoading: false,
        }
      }

      if (!currentProductFeatures) {
        return {
          hasPermission: false,
          reason: "missing-product-features",
          isLoading: false,
        }
      }

      const productFeature = currentProductFeatures[feature]

      if (!productFeature) {
        return {
          hasPermission: false,
          reason: `missing-product-feature: ${feature}`,
          isLoading: false,
        }
      }

      if (productFeature.max) {
        if (numOfEntities && numOfEntities > productFeature.max)
          return {
            hasPermission: false,
            reason: productFeature.errorReason,
            max: productFeature.max,
            isLoading: false,
          }
        return {
          hasPermission: true,
          max: productFeature.max,
          isLoading: false,
        }
      }

      if (!productFeature.enabled) {
        return {
          hasPermission: false,
          reason: productFeature.errorReason,
          isLoading: false,
        }
      }

      return {
        hasPermission: true,
        max: productFeature.max,
        isLoading: false,
      }
    },
    [currentProductFeatures, isSubscriptionLoading, wsId]
  )

  const getPermission = useCallback(
    (resourceAction: ResourceAction): AclPermissionResolutionResult => {
      const { resource, action } = resourceAction

      const isWorkspaceScope = resourceAction.resource === "workspaces"
      const { data: currentRole, isPending: isPendingCurrentRole } =
        getCurrentRole({ isWorkspaceScope })

      if (isPendingCurrentRole || isAclsLoading) {
        return { hasPermission: false, reason: "loading", isLoading: true }
      }

      if (!wsId) {
        return {
          hasPermission: false,
          reason: "missing-workspace",
          isLoading: false,
        }
      }

      if (!acls) {
        return {
          hasPermission: false,
          reason: "missing-acls",
          isLoading: false,
        }
      }

      if (!currentRole) {
        return {
          hasPermission: false,
          reason: "missing-role",
          isLoading: false,
        }
      }

      // TODO (ACL): This is a workaround for `designSystems` so that editor+ can see design data and code automation pages
      if (resource === "designSystems") {
        if (
          !currentRole ||
          currentRole === UserRole.viewer ||
          currentRole === UserRole.billing
        ) {
          return {
            hasPermission: false,
            reason: "no-access",
            isLoading: false,
          }
        }

        return {
          hasPermission: true,
          isLoading: false,
        }
      }

      // FIXME: This is a workaround for `dsBrands` to hide the page from non-editors
      if (resource === "designSystemBrands") {
        if (
          !currentRole ||
          currentRole === UserRole.viewer ||
          currentRole === UserRole.billing
        ) {
          return {
            hasPermission: false,
            reason: "no-access",
            isLoading: false,
          }
        }

        if (
          currentRole === UserRole.creator ||
          currentRole === UserRole.contributor
        ) {
          return action === "read"
            ? {
                hasPermission: true,
                isLoading: false,
              }
            : {
                hasPermission: false,
                reason: "no-access",
                isLoading: false,
              }
        }

        return {
          hasPermission: true,
          isLoading: false,
        }
      }

      // FIXME: This is a workaround for `integrations` to setup the page rights
      if (resource === "workspaces" && action.includes("integration.")) {
        const [, actionTarget, actionType] = action.split(".") // read, create, delete
        if (
          currentRole === UserRole.viewer ||
          currentRole === UserRole.billing ||
          currentRole === UserRole.contributor ||
          currentRole === UserRole.creator
        ) {
          return {
            hasPermission: false,
            reason: "No permission to view integrations",
            isLoading: false,
          }
        }

        if (
          actionTarget === "gitProviders" &&
          (actionType === "create" || actionType === "delete")
        ) {
          if (
            currentRole !== UserRole.owner &&
            currentRole !== UserRole.admin
          ) {
            return {
              hasPermission: false,
              reason: "No permission to create or delete git providers",
              isLoading: false,
            }
          }
        }

        if (actionTarget === "figmaProviders") {
          if (
            actionType === "create" &&
            currentRole !== UserRole.owner &&
            currentRole !== UserRole.admin
          ) {
            return {
              hasPermission: false,
              reason: "No permission to create figma providers",
              isLoading: false,
            }
          }

          if (
            actionType === "delete" &&
            currentRole !== UserRole.owner &&
            currentRole !== UserRole.admin
          ) {
            return {
              hasPermission: false,
              reason: "No permission to delete figma providers",
              isLoading: false,
            }
          }
        }

        return {
          hasPermission: true,
          isLoading: false,
        }
      }

      // TODO (ACL): This is a workaround to hide the Members page for viewers
      if (resource === "workspaces" && action === "members.read") {
        if (!currentRole || currentRole === UserRole.viewer) {
          return {
            hasPermission: false,
            reason: "no-access",
            isLoading: false,
          }
        }
      }

      return {
        ...acls.hasPermission(resource, action, currentRole),
        isLoading: false,
      }
    },
    [acls, getCurrentRole, isAclsLoading, wsId]
  )

  // TODO (ACL): rename when `getPermission` is removed
  const getPermissionNew = useCallback(
    (op: AclOperation) => {
      const isDsOperation = isDesignSystemOperation(op)

      const { data: currentRole, isPending: isPendingCurrentRole } =
        getCurrentRole({ isWorkspaceScope: !isDsOperation })

      if (isPendingCurrentRole) {
        return { hasPermission: false, reason: "loading", isLoading: true }
      }

      if (!wsId) {
        return {
          hasPermission: false,
          reason: "missing-workspace",
          isLoading: false,
        }
      }

      if (!currentRole) {
        return {
          hasPermission: false,
          reason: "missing-role",
          isLoading: false,
        }
      }

      const hasPermission = aclSafeCheckAccess(currentRole, op)

      if (printAclDebugInfo) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(AclOperations)) {
          if (value === op) {
            console.log(key)
            break
          }
        }
        console.log("Scope:", isDsOperation ? "DS" : "WS")

        console.log("currentRole:", currentRole)
        console.log("hasPermission:", hasPermission)
        console.log("==============")
      }

      return {
        hasPermission,
        isLoading: false,
      }
    },
    [printAclDebugInfo, getCurrentRole, wsId]
  )

  // TODO (ACL): rename to `hasPermission` when the old ACL system is removed
  const hasPermissionNew = useCallback(
    (op: AclOperation | AclOperation[]) => {
      const opArray = Array.isArray(op) ? op : [op]

      // eslint-disable-next-line no-restricted-syntax
      for (const operation of opArray) {
        const { hasPermission } = getPermissionNew(operation)

        if (!hasPermission) {
          return false
        }
      }

      return true
    },
    [getPermissionNew]
  )

  return {
    /**
     * @deprecated `getPermissionNew` should be used instead
     */
    getPermission,
    getFeaturePermission,
    getPermissionNew,
    hasPermissionNew,
  }
}
