import { AclOperations } from "@supernova-studio/acl"

import {
  BillingPlanPageRouteDestination,
  BillingPlanRouterType,
  BrandRouteDestination,
} from "../../utils/routing/types"
import { useParams } from "../useParams"
import { usePermissions } from "../usePermissions"

import { useBrandRouteWithDestination } from "./useBrandRouteWithDestination"

export function useSelectPlanRoute(
  seatCount?: number
): BillingPlanRouterType | undefined {
  const { wsId, dsId, versionId, brandId } = useParams()
  const { hasPermissionNew } = usePermissions(wsId, dsId)

  const selectPlanRoute = useBrandRouteWithDestination(
    wsId,
    dsId,
    versionId,
    brandId,
    BrandRouteDestination.plan
  )

  const canUpdateSubscription = hasPermissionNew(
    AclOperations.WorkspaceSubscriptionUpdate
  )

  if (!canUpdateSubscription || !selectPlanRoute) {
    return undefined
  }

  const route = `${selectPlanRoute}/${BillingPlanPageRouteDestination.selectPlan}`
  if (seatCount !== undefined) {
    const url = new URL(window.location.origin.concat(route))
    url.searchParams.set("seatCount", seatCount.toString())
    return url.toString()
  }
  return route
}
